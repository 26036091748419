import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const Home = () =>  import('../views/Web/Home.vue')
const Introduce = () =>  import('../views/Web/Introduce.vue')
const About = () =>  import('../views/Web/About.vue')
const Download = () =>  import('../views/Web/Download.vue')
const LostCard = () => import('../views/Web/LostCard.vue')
const Knowledge = () => import('../views/Web/Knowledge.vue')
const KnowledgeDetail = () => import('../views/Web/KnowledgeDetail.vue')


const routes = [
  // {
  //   path:'/web',
  //   redirect:'/home'
  // },
  {
    path:'/mi',
    redirect:'/mhome'
  },
  {
    // path: '/web',
    path: '/',
    name: 'Web',
    component: () => import('../views/Web/Web.vue'),
    children:[
      {
        // path: '/home',
        path: '/',
        name: 'Home',
        component:  Home
      },
      {
        path: '/lostcard',
        name: 'LostCard',
        component: LostCard
      },
      {
        path: '/about',
        name: 'About',
        component: About
      },
      {
        path: '/introduce',
        name: 'Introduce',
        component:  Introduce
      },
      {
        path: '/download',
        name: 'Download',
        component:  Download
      },
      {
        path: '/knowledge',
        name: 'Knowledge',
        component:  Knowledge
      },
      {
        path: '/knowledgeDetail',
        name: 'KnowledgeDetail',
        component:  KnowledgeDetail
      }
    ]
  },
  {
    path: '/mi',
    name: 'Mi',
    component: () => import('../views/Mi/Mi.vue'),
    children:[
      {
        path: '/mhome',
        name: 'mHome',
        component:  () => import('../views/Mi/mHome.vue')
      },
      {
        path: '/mabout',
        name: 'mAbout',
        component: () => import('../views/Mi/mAbout.vue')
      },
      {
        path: '/mlostcard',
        name: 'mLostCard',
        component: () => import('../views/Mi/mLostCard.vue')
      },
      {
        path: '/mintroduce',
        name: 'mIntroduce',
        component: () => import('../views/Mi/mIntroduce.vue')
      },
      {
        path: '/mdownload',
        name: 'mDownload',
        component: () => import('../views/Mi/mDownload.vue')
      },
      {
        path: '/mknowledge',
        name: 'mKnowledge',
        component: () => import('../views/Mi/mKnowledge.vue')
      },
      {
        path: '/mknowledgeDetail',
        name: 'mKnowledgeDetail',
        component: () => import('../views/Mi/mKnowledgeDetail.vue')
      }
    ]
  },
  
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router

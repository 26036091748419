<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import DevicePixelRatio from './utils/devicePixelRatio'
// import DevicePixelRatio from './utils/rem1'
export default {
  created() {
    new DevicePixelRatio().init()

    //----------------- start -----------------
    // 判断PC、移动端测试
    // if (/Mobil|Android|iPhone/i.test(navigator.userAgent)) {
    //   console.log('当前设备是移动设备');
    // } else {
    //   console.log('PC端');
    // }
    //------------------ end ------------------

  },
  mounted() {
    this.detect()
    //禁止网页缩放
    window.addEventListener(
      "wheel",
      function (event) {
        if (event.ctrlKey === true || event.metaKey) {
          event.preventDefault();
        }
      },
      { passive: false }
    );

    //firefox
    window.addEventListener(
      "DOMMouseScroll",
      function (event) {
        if (event.ctrlKey === true || event.metaKey) {
          event.preventDefault();
        }
      },
      { passive: false }
    );
  },
  methods: {

    // _isMobile() {
    //   let flag = navigator.userAgent.match(/(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    //   return flag;
    // },
    // _isIpad() {
    //   let flag = navigator.userAgent.match(/(pad|iPad)/i)
    //   return flag;
    // },
    detect() {
      var equipmentType = " ";
      var agent = navigator.userAgent.toLowerCase();
      //android ios windows
      var android = agent.indexOf("android");
      var iphone = agent.indexOf("iphone");
      var ipad = agent.indexOf("ipad");
      var windows = agent.indexOf("windows");
      var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
      if (android != -1 || iphone != -1) {
        this.$router.replace('/mi');
        return
      }
      if (ipad != -1) { // || isMac
        this.$router.replace('/mi');
        return
      }
      if (windows != -1 || isMac) {
        // this.$router.replace('/web');
        this.$router.replace('/');
        return
      }
      return equipmentType;
    }

  },
}

</script>
<style lang="scss">
@font-face {
  font-family: "SourceHanSansCN-Medium";
  src: url("./assets/font/SourceHanSansCN-Medium.otf");
}
@font-face {
  font-family: "SourceHanSansCN-Bold";
  src: url("./assets/font/SOURCEHANSANSCN-BOLD.OTF");
}
.amap-logo,
.amap-copyright {
  display: none !important;
}
#app {
  width: 100%;
  height: 100%;
}
* {
  padding: 0;
  margin: 0;
  white-space: nowrap;
}

image,
img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
</style>
